import React from 'react';
import { ShVideoReview, ShGridProvider } from '@shoootin/components-web';
import { usePageParams } from '../../layout/appContext';

const VideoReview = () => {
  const { videoId } = usePageParams();

  return (
    <ShGridProvider>
      <ShVideoReview videoId={videoId!} userType={'client'} />
    </ShGridProvider>
  );
};

export default VideoReview;
